import React from 'react'
import Layout from '../components/layout'
import Seo from "../components/seo";
import UserPersonaWhitePaper from '../docs/729-Solutions-User-Personas-Paint-Pictures-to-Drive-Business-Decisions.pdf'

const ThankYouDownloadUserPersonaWhitePaper = ({ location }) => {
    return (
        <Layout>
            <Seo pathName={location.pathname} title="Thank you" />
            <section className="pt-6">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>Thank you!</h2>
                            <p>Download our User Persona White Paper with the button below.</p>
                            <a href={UserPersonaWhitePaper} className="btn btn-primary-light" target="_blank" rel="noreferrer">
                                Download our User Persona White Paper PDF
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ThankYouDownloadUserPersonaWhitePaper
